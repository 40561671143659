.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e1e1e1;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.015em;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.paginagion-link-active {
  background-color: var(--light-green);
}

.paginagion-link-active:hover {
  color: #fff;
}

.arrow-previous {
  transform: rotate(-180deg);
  color: var(--light-green);
}

.arrow-next {
  margin-left: 10px;
  color: var(--light-green);
}

.arrow-inactive {
  color: #e1e1e1;
}

.arrow-active {
  color: var(--light-green);
}

.pagination-arrow-container {
  display: flex;
  align-items: center;
}
