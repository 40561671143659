.my-app-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.my-app-content {
  width: 100%;
}

#app-name-text {
  text-align: end;
  color: var(--main-green);
}

.my-app-authenticated svg {
  height: 500px;
  width: 100%;
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .app-name-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    width: auto;
  }
}

@media (min-width: 992px) {
  .my-app {
    flex-direction: row-reverse;
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .my-app-container {
    flex-direction: column;
    justify-content: unset;
  }
}
