.dashboard-admin-pending-rides {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dashboard-admin-pending-rides-text {
  padding: 1rem;
  margin: 0;
}

.dashboard-admin-pending-rides-quantity {
  font-size: 3rem;
  padding: 1rem;
}
