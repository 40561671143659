.manager-dashoboard-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.faturation-manager-dashoboard-container {
  display: grid;
  gap: 1.5rem;
  margin-top: 3rem;
}
.regular-manager-dashoboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: end;
  height: 70vh;
  padding: 0 30px;
}

.regular-manager-dashoboard-container svg {
  width: 250px;
  height: 300px;
}

@media screen and (min-width: 576px) {
  .regular-manager-dashoboard-container svg {
    width: 350px;
  }
}

@media screen and (min-width: 768px) {
  .faturation-manager-dashoboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .regular-manager-dashoboard-container svg {
    width: 450px;
    margin-top: 2rem;
  }
}
