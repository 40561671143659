.vehicles-card-container {
  border-radius: 10px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.vehicle-image-container {
  text-align: center;
}

.vehicle-image-container img {
  width: 90%;
}

.vehicle-text-container {
  padding: 1rem;
  min-height: 180px;
}

.vehicle-text-container h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 576px) {
  .vehicle-image-container img {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .vehicle-text-container {
    min-height: 230px;
  }
}

@media screen and (min-width: 1400px) {
  .vehicle-text-container {
    min-height: 200px;
  }
}
