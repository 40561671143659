.login-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.login-banner-container {
  display: none;
}

.login-form-container {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .login-form-container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .login-form-container {
    width: 550px;
  }
}

@media screen and (min-width: 1200px) {
  .login-container {
    padding: 40px;
  }

  .login-banner-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 500px;
  }

  .login-banner-container h1 {
    font-size: 40px;
    letter-spacing: -0.015em;
    color: #263238;
    margin-bottom: 15px;
  }

  .login-banner-container p {
    font-size: 18px;
    letter-spacing: -0.015em;
    color: #9e9e9e;
  }

  .login-banner-container svg {
    width: 100%;
    height: 390px;
  }
}

@media (min-width: 1400px) {
  .login-banner-container {
    width: 650px;
  }

  .login-banner-container h1 {
    font-size: 55px;
    margin-bottom: 25px;
  }

  .login-banner-container p {
    font-size: 24px;
  }
}
