.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--aqua-green);
  margin: 1rem;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 10px;
}

.header-text-container h1 {
  font-size: 1.6rem;
  text-align: center;
}

.header-text-container h2 {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.header-image {
  width: 95%;
}

@media screen and (min-width: 768px) {
  .header-container {
    padding: 2rem;
  }

  .header-image {
    width: 75%;
  }
}

@media screen and (min-width: 768px) {
  .header-container {
    padding: 3rem;
  }

  .header-text-container h1 {
    font-size: 1.9rem;
  }

  .header-text-container h2 {
    font-size: 1rem;
  }
}
