.user-details-name {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(172, 172, 172);
}

.user-card-item {
  margin: 0;
}

.user-details-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
