.main-nav {
  padding-left: 40px;
  min-height: 70px;
  box-shadow: 0px 8px 16px rgba(69, 69, 69, 0.25);
}

.nav-logo-text {
  color: var(--main-green);
}

.nav-logo-text:hover {
  color: var(--active-navigation-item-color);
  transition: 0.3s;
}

.main-menu {
  display: flex;
  font-size: 18px;
  width: 200px;
  justify-content: space-between;
}

.main-menu a {
  font-style: normal;
  font-weight: bold;
}

.main-menu a.active {
  color: #00b956;
  font-weight: bold;
}

.main-menu a:hover {
  color: var(--active-navigation-item-color);
  transition: 0.3s;
}

.nav-login-logout {
  display: none;
}

.nav-username {
  display: none;
}

.navbar-my-app-btn {
  border: none;
  background: none;
  font-weight: bold;
}

.navbar-my-app-btn:hover {
  color: #00b956;
  transition: 0.3s;
}

.active-btn {
  color: #00b956;
}

@media (min-width: 768px) {
  .nav-login-logout {
    display: unset;
    margin-right: 15px;
  }

  .logoutNavLink {
    display: none;
  }
}

@media (min-width: 992px) {
  .nav-login-logout {
    margin-right: 40px;
  }

  .nav-username {
    display: unset;
    margin-right: 20px;
  }
}
