@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

html {
  position: relative;
  min-height: 100vh;
}

body {
  position: relative;
  min-height: 100vh;
}

.app-container {
  position: relative;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: unset;
}

.base-card {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.secondary-base-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(69, 69, 69, 0.25);
  margin-bottom: 1em;
  padding: 1em;
}

.secondary-base-card h1 {
  font-size: 1.3em;
}

.secondary-base-card p {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0;
}

.has-error {
  border: 1px solid #dc3545;
  padding: 1rem;
  margin-top: 0.8rem;
  font-size: 1rem;
  border-radius: 10px;
  color: #dc3545;
  font-weight: 400;
}

.details-card-title {
  margin-bottom: 10px;
  color: var(--main-green);
  font-size: 1.4rem;
}

.details-separator {
  border: 2px solid var(--main-green);
  opacity: 0.6;
}

@media (min-width: 992px) {
  .secondary-base-card {
    padding: 1.5em;
  }

  .details-card-title {
    font-size: 2rem;
  }
}
