.register-form-container {
  padding: 30px 20px;
  text-align: center;
}

@media screen and (min-width: 576px) {
  .register-form-container {
    padding: 50px;
    text-align: center;
  }
}
