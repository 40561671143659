.my-app-nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -4px 16px rgba(69, 69, 69, 0.25);
  position: fixed;
  bottom: 0;
}

.my-app-nav-wrapper {
  width: 100%;
}

.my-app-nav-option {
  display: none;
  align-items: center;
  width: 100%;
  font-size: 16px;
  margin-left: 10px;
  text-align: left;
}

.active-nav-item svg {
  color: #00b956;
  border-radius: 5px;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);
}

@media (min-width: 992px) {
  .my-app-nav-list a:hover {
    background-color: #d4dbd7;
  }

  .my-app-nav-option {
    display: flex;
  }

  .active-nav-item {
    color: #00b956;
  }

  .active-nav-item svg {
    border-radius: 0;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .my-app-nav-container {
    position: relative;
  }
}
