.invoice-filter-city-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 16px;
}

.invoice-filter-city {
  flex-grow: 1;
}

.invoice-filter-layout-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
}

.invoice-filter-company-item {
  width: 100%;
}

.invoice-filter-btn-container {
  display: flex;
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
}

.invoice-filter-btn-container button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .invoice-filter-company-item {
    width: unset;
    flex: 1;
  }
}

@media screen and (min-width: 992px) {
  .invoice-filter-company-item {
    flex: 1;
  }

  .invoice-filter-btn-container {
    flex: 1;
  }
}
