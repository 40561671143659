.company-details-container {
  padding: 2rem 1rem;
  margin-top: 2rem;
}

.company-details-infos-contact-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 1rem;
}

.company-details-infos-contact-container h1 {
  margin-right: 1rem;
}

.company-details-infos-contact-container p {
  margin: 2px 0.8rem 0 0;
}

.company-details-infos-title {
  font-size: 1.5rem;
  color: var(--main-green);
  margin-bottom: 1rem;
}

.company-details-user-container {
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 500px;
  overflow: auto;
}

.company-details-user-title {
  font-size: 1.5rem;
  color: var(--main-green);
  margin-bottom: 0.5rem;
}

.company-details-btn-container {
  text-align: end;
  margin-top: 1.5rem;
}

.company-manager-delete {
  font-size: 26px;
}

.company-manager-delete:hover {
  color: red;
  transition: 0.5s;
}
