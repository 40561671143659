.input {
  padding: 5px;
  border-radius: 4px;
  border-style: none;
  display: flex;
  border: 1px solid black;
}

.form {
  display: grid;
  gap: 1px;
}
