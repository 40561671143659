@keyframes fadeIn {
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.transition-open {
  animation: fadeIn 0.8s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.transition-close {
  animation: fadeOut 0.8s forwards;
}

.current-driver-ride-btn-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.current-driver-ride-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-driver-ride-card p {
  margin-bottom: 5px;
}
.current-driver-ride-card-infos {
  flex: 1;
}

.current-driver-ride-arrow-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

@media screen and (min-width: 576px) {
  .current-driver-ride-btn-container {
    justify-content: flex-end;
    gap: 16px;
  }
}
