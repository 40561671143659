.invoice-actions-container {
  display: flex;
  row-gap: 8px;
  column-gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.invoice-actions-container button {
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.save-invoice-no-content-container {
  text-align: center;
  margin: 64px 0 32px 0;
}

@media screen and (min-width: 576px) {
  .invoice-actions-container button {
    width: 30%;
  }
}

@media screen and (min-width: 768px) {
  .invoice-actions-container {
    justify-content: center;
  }
  .invoice-actions-container button {
    width: 28%;
  }
}
