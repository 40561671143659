.animeted-list-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 1.2em;
  padding-top: 20px;
  padding: 15px;
  border-bottom: 1px solid rgb(172, 172, 172);
  box-shadow: inset 0 0 0 0 #bae9c8;
  transition:
    color 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;
}

.animeted-list-item-container:hover {
  box-shadow: inset 100vw 0 0 0 #bae9c8;
  color: var(--main-green);
  font-weight: bold;
}

.animeted-list-item {
  margin: 0;
}
