.ride-expense-btn {
  width: 100px;
}

.ride-expense-btn-container {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.require-expense-type__control {
  border: 1px solid #dc3545 !important;
}
