.current-ride-status-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
}

.current-ride-status-icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  color: var(--light-green);
}

.current-ride-status-text {
  display: flex;
  align-items: center;
  flex: 1;
  height: 50px;
  font-size: 1rem;
  text-align: start;
  margin: 0;
  height: 50px;
}

.current-ride-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.current-ride-buttons-container button {
  width: 100%;
}

.swal2-html-container {
  z-index: 100;
  overflow: unset;
}

@media screen and (min-width: 576px) {
  .current-ride-buttons-container {
    flex-direction: row;
    justify-content: end;
  }
}

@media screen and (min-width: 992px) {
  .current-ride-buttons-container button {
    width: 25%;
  }
}
