.card-container {
  display: flex;
  align-items: center;
  background-color: #ccf1dd;
  border-radius: 10px;
  padding: 0.8rem 0.5rem;
  gap: 10px;
}

.card-icon-container i {
  font-size: 2.5rem;
}

.card-text-container h1 {
  font-size: 1rem;
}

.card-text-container p {
  margin-bottom: 0px;
}

@media screen and (min-width: 576px) {
  .card-container {
    flex-direction: column;
    text-align: center;
  }

  .card-text-container h1 {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 992px) {
  .card-container {
    flex-direction: row;
    text-align: unset;
  }
}
