.page-title {
  font-size: 1.5rem;
}

.page-subtitle {
  font-size: 1rem;
  margin: 1rem 0;
  color: var(--main-green);
}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 2rem;
  }

  .page-subtitle {
    font-size: 1.3rem;
  }
}
