.login-redirect-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  width: 80%;
  margin: auto;
  gap: 3rem;
}

.login-redirect-link {
  font-size: 1.8rem;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;

  background-image: linear-gradient(
    to right,
    #17c568,
    var(--main-green) 50%,
    #000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.login-redirect-link:before {
  content: '';
  background: #0e8144;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.login-redirect-link:hover {
  background-position: 0;
}

.login-redirect-link:hover::before {
  width: 100%;
}

.login-redirect-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-redirect-img svg {
  width: 100%;
  height: fit-content;
}

@media screen and (min-width: 576px) {
  .login-redirect-img svg {
    width: 80%;
    height: fit-content;
  }
}

@media (min-width: 768px) {
  .login-redirect-link {
    font-size: 2.2rem;
  }

  .login-redirect-img svg {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .login-redirect-img svg {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .login-redirect-img svg {
    width: 55%;
  }
}
