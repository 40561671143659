.swiper-slide {
  width: 500px;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: var(--light-green);
}

.swiper-pagination {
  position: unset;
  margin-top: 0.5rem;
}

@media screen and (min-width: 576px) {
  .swiper-pagination {
    margin-top: 1rem;
  }
}
