.ride-details-expense-btn-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
}

@media screen and (min-width: 768px) {
  .ride-details-expense-btn-container {
    flex-direction: row;
    column-gap: 1rem;
  }
  .ride-details-expense-btn-container button {
    width: 100%;
  }
}
