.navigation-items-container {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.navigation-item {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.navigation-item:hover {
  color: var(--active-navigation-item-color);
  transition: 0.3s;
}

.active-navigation-item {
  color: var(--active-navigation-item-color);
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (min-width: 992px) {
  .navigation-items-container {
    margin: 1rem 0;
  }

  .navigation-item {
    flex-direction: row;
  }
}
