@keyframes fadeIn {
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.dashboard-passenger-container {
  padding: 20px 15px;
  position: relative;
  animation: fadeIn 1s forwards;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
