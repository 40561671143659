.recover-password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 70px);
}

.recover-password-content {
  width: 100%;
}

.recover-password-content-wrapper {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 32px;
  border-radius: 10px;
}

.recover-password-app-icon {
  color: var(--main-green);
  text-align: center;
  margin-bottom: 64px;
  font-size: 1.8rem;
}

.recover-password-field-label {
  margin-bottom: 12px;
  margin-left: 5px;
}

.recover-password-separator-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  align-self: stretch;
  margin: 24px 0;
}

.recover-password-separator-container hr {
  border: 1px solid var(--grey-color);
  width: 100%;
}

.recover-password-separator-container span {
  display: flex;
  align-items: center;
  background-color: var(--white-color);
}

.recover-password-login-container {
  display: flex;
  justify-content: center;
}

.recover-password-login-container a {
  font-weight: bold;
  margin-left: 6px;
  cursor: pointer;
}

@media screen and (min-width: 576px) {
  .recover-password-content {
    padding: 0 20%;
  }
}

@media screen and (min-width: 1200px) {
  .recover-password-container {
    flex-direction: row-reverse;
  }
  .recover-password-content {
    padding: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;
  }
}
