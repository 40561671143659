.request-race-container p {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}

.request-race-img-container {
  text-align: center;
}
.request-race-img-container svg {
  width: 250px;
  height: 150px;
}
