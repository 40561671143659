.vehicles-category-container {
  margin: 1rem;
}

.vehicles-category-title {
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (min-width: 576px) {
  .vehicles-category-title {
    margin-bottom: 1.5rem;
  }
}
