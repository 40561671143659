.home-cards-container {
  margin: 3.5rem 1rem;
}

.home-cards-qualities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.home-cards-title {
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (min-width: 576px) {
  .home-container {
    margin: 2rem 3rem;
  }

  .home-cards-qualities {
    flex-direction: row;
  }

  .home-cards-title {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}
