.ride-data-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .ride-data-container {
    width: 35%;
  }
}
