.race-details-item-container {
  align-items: baseline;
  border-bottom: 1px solid rgb(187, 185, 185);
  margin-bottom: 10px;
  padding: 5px;
}

.race-details-item-container h4 {
  font-size: 14px;
  color: var(--main-green);
}

.race-details-item-container p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.expense-item-container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.expense-item-delete-icon {
  font-size: 24px;
}

.expense-item-delete-icon:hover {
  color: red;
  transition: 0.5s;
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .race-details-item-container h4 {
    font-size: 16px;
  }

  .race-details-item-container p {
    font-size: 18px;
  }
}
