.table-container {
  width: 100%;
  border-spacing: 0;
  margin-top: 20px;
  overflow-y: scroll;
}

.table-container thead th {
  height: 55px;
  text-align: left;
  font-size: 16px;
  padding: 10px;
}

.table-container tbody td {
  height: 55px;
  font-size: 16px;
  border-top: 1px solid var(--light-green);
  transition: background 0.3s;
  padding: 10px;
}

.table-animation-effect {
  border-bottom: 1px solid rgb(172, 172, 172);
  box-shadow: inset 0 0 0 0 #bae9c8;
  transition:
    color 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

.table-animation-effect:hover {
  box-shadow: inset 100vw 0 0 0 #bae9c8;
  color: var(--main-green);
}

.overflow {
  overflow-x: auto;
}
