.dashoboard-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.dashoboard-container {
  display: grid;
  gap: 1.5rem;
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .dashoboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 5rem;
  }
}
