.footer-container {
  color: #fff;
  background-color: #8fbba4;
  margin: 1rem;
  margin-top: 2.5rem;
  padding: 1rem;
  border-radius: 10px;
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  background-color: #8fbba4;
  justify-content: space-between;
}

.footer-information-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.footer-information-container h1 {
  font-size: 1.1rem;
}

.footer-information-container p {
  font-size: 0.9rem;
  margin-bottom: 0px;
}

.footer-social-media-container {
  display: flex;
  gap: 0.5rem;
  font-size: 1.5rem;
}

.footer-copyright-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid #fff;
  padding-top: 10px;
  gap: 0.5rem;
}

@media screen and (min-width: 768px) {
  .footer-content-container {
    align-items: center;
  }

  .footer-logo {
    width: 400px;
  }

  .footer-content-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .footer-logo {
    width: 200px;
  }
}
