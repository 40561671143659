.login-card {
  padding: 20px;
  font-size: 16px;
}

.login-card h1 {
  font-size: 1.4rem;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.015em;
  color: #263238;
  text-transform: uppercase;
  margin-top: 70px;
  margin-bottom: 50px;
}

.login-link-recover {
  font-size: 1em;
  letter-spacing: -0.015em;
  color: var(--light-green);
  display: block;
  margin-bottom: 90px;
}

.login-link-register {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  color: var(--light-green);
  margin-left: 10px;
}

.login-submit {
  margin-bottom: 10px;
}

.not-registered {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9e9e9e;
}

.login-loading {
  display: flex;
  justify-content: center;
  max-height: 70px;
}

.signup-container {
  text-align: center;
  margin-bottom: 30px;
}

.singup-select__control {
  background-color: #f8f4f4 !important;
  border-radius: 0.375rem !important;
}

@media (min-width: 576px) {
  .login-card {
    padding: 50px;
  }
}

@media screen and (min-width: 768px) {
  .login-card h1 {
    font-size: 2rem;
  }
}
