.btn-container {
  display: flex;
}

.btn-container button {
  width: calc(100% - 50px);
  border-radius: 10px 0 0 10px;
}

.btn h6 {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

.btn-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  background-color: var(--main-green);
  color: #fff;
}

@media (min-width: 576px) {
  .btn-icon-container {
    width: 70px;
    height: 70px;
  }

  .btn-container button {
    width: calc(100% - 70px);
  }

  .btn h6 {
    font-size: 18px;
  }
}
